<template>
  <main class="chat">
  <div class="holder">
      <a id="backlink" @click="goBack"> {{ $t('common.back') }}
        <span class="item" v-show="goods.resourceId" style="padding-bottom: 5px">
          <span class="img-holder">
            <img :src="createResourceUrl(goods.resourceId)"
                 :alt="goods.title"
                 :title="goods.title">
          </span>
          {{ goods.title }}
        </span>
      </a>

      <section id="chat">
        <infinite-loading direction="top" :distance="infiniteScrollDistance" forceUseInfiniteWrapper="true"
                          :identifier="infinite" @infinite="loadMessages">
          <div slot="spinner"></div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
          <div slot="error"></div>
        </infinite-loading>
        <ul class="messages">
          <li v-for="item in messages.slice().reverse()"
              :class="[item.ownerId === user.id ? 'green r' : '', {'img' : item.type === 'image'}]"
              :key="item.id">
            <div>
              <span v-if="item.type === 'text'" style="white-space:pre-line">{{item.text}}</span>
              <img v-if="item.type === 'geo'" class="gmaps-icon" @click="() => onGeoClicked(item)" src="../../public/images/google_maps.png" alt="">
              <img v-if="item.type === 'image'" :src="createResourceUrl(item.resourceId)" @click="onOpenPhotoModalClick(item.resourceId)">
              <div class="datetime">{{ item.created | chatTimeFilter }}{{ item.read ? (', ' + $t('chat.read')) : null }}</div>
            </div>
          </li>
        </ul>

        <div class="mn">
          <button id="chat-mn-btn" v-if="$route.name !== 'newChat'">{{ $t('chat.open-menu') }}</button>
          <ul id="chat-mn">
            <li @click="onApproveClick">
              <img
                      src="../../public/images/ico_basket.svg"
                      :alt="$t('chat.approve')"
                      :title="$t('chat.approve')"> {{ $t('chat.approve') }}
            </li>
            <li class="lock" data-target="#modal-end-chat" @click="onEndChatClick">
              <img src="../../public/images/ico_lock.svg"
                   :alt="$t('chat.end-chat')"
                   :title="$t('chat.end-chat')"> {{ $t('chat.end-chat') }}
            </li>
            <li class="complain" @click="onComplaintClicked">
              <img
                  src="../../public/images/ico_complain.svg"
                  :alt="$t('chat.report')"
                  :title="$t('chat.report')"> {{ $t('chat.report') }}
            </li>
          </ul>
        </div>

        <div class="actions">
          <input type="text" name="message" :placeholder="$t('chat.message')" id="chat-input" autocomplete="off"
                 v-model="newMessage.text" @keyup="onEnterPressed">
          <div class="send">
            <button :disabled="newMessage.text.length === 0" id="chat-send-btn" @click="onSendMessageClick">{{ $t('chat.send') }}
            </button>
          </div>
          <div class="attachments" v-if="$route.name !== 'newChat'">
            <button id="attachments-mn-btn"></button>
            <div class="attachments-mn" id="attachments-mn">
              <button class="photo js-modal-link" id="chat-attach-photo" data-target="#modal-upload-photo">{{ $t('chat.photo') }}</button>
              <button class="location" @click="onChooseLocationClick">
                {{ $t('chat.location') }}
              </button>
              <button class="cdek" id="chat-cdek" v-show="chatDTO.delivery === true" @click="onCDEKClick">CDEK</button>
              <button class="order__btn" v-show="chatDTO.delivery === true">
                <img src="../../public/images/sdek.svg" alt="логотип" @click="onCDEKClick">
              </button>
              <button class="book" id="chat-booking" @click="onBookingClick" v-show="goods.owner.id !== user.id">
                {{ $t('chat.booking') }}
              </button>
              <button class="approve" id="chat-approve" @click="onApproveClick">
                {{ $t('chat.approve') }}
              </button>
            </div>
          </div>
        </div>

        <div id="chat-fadeout"></div>
      </section>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import chatTimeFilter from '../assets/filters/chatTimeFilter.js';
  import modal from '../assets/js/init/modal';
  import { eventBus } from '@/main';
  import chatInit from '../assets/js/init/chat';
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'Chat',
    title: 'Chat',
    filters: {
      chatTimeFilter,
    },
    components: { InfiniteLoading },
    data() {
      return {
        chatId: this.$route.params.id,
        newMessage: {
          type: 'text',
          text: '',
        },
        messages: [],
        infinite: +new Date(),
        infiniteScrollDistance: 600,
        goods: {
          id: null,
          resourceId: null,
          goodsTitle: null,
          owner: {id: null}
        },
        timer: null,
        chatDTO: {
          delivery: false,
        },
      };
    },
    created() {
      this.loadComplaintCategories();

      let loadChatParams = {
        id: this.$route.params.id,
      };

      this.loadChatById(loadChatParams).then((response) => {
        this.chatDTO = response.data;
      });

      this.chatId = this.$route.params.id;
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type == 'addMessages' || mutation.type == 'addNewMessage' || mutation.type == 'initMessages') {
          let doScroll = this.messages.length === 0;

          this.messages = state.chatModule.messages[this.chatId].slice();
          if (doScroll) {
            this.$nextTick(() => {
              let el = this.$el.querySelector('.messages');
              el.scrollTop = el.scrollHeight - el.clientHeight;
            });
          }
        }
      });
      this.initMessages(this.chatId);

      if (this.$route.params.goodsId !== undefined && this.$route.params.goodsId !== null) {
        this.goods.id = this.$route.params.goodsId;
        this.goods.resourceId = this.$route.params.goodsResource;
        this.goods.title = this.$route.params.goodsTitle;
        this.goods.owner = {id: this.$route.params.goodsOwnerId}
      } else if (this.$route.name === 'newChat') {
        this.loadGoodsById({ id: this.$route.params.id })
          .then((result) => {
            this.goods.id = result.data.id;
            this.goods.resourceId = result.data.resourceIds[0];
            this.goods.title = result.data.title;
            this.goods.owner = result.data.owner;
          });
      } else {
        this.loadGoodsByChatId({ id: this.$route.params.id })
          .then((result) => {
            this.goods.id = result.data.id;
            this.goods.resourceId = result.data.resourceIds[0];
            this.goods.title = result.data.title;
            this.goods.owner = result.data.owner;
          });
      }

      eventBus.$on('applyMarker', (data) => {
        if(data.length > 0) {
          this.sendGeo(data[0].geo);
        }
      });

      eventBus.$on('imageForMessageLoaded', resourceId => this.sendPhoto(resourceId));

      eventBus.$on('onApproveDeal', () => {
            this.confirmDeal({
                id: this.goods.id,
                body: {
                    accept: true,
                    chatId: this.$route.params.id,
                }
            }).catch(error => {
                this.showToast(error.response.data.message, 'error');
            });
        });

      eventBus.$on('onBookingDeal', () => {
            this.bookingDeal({
                id: this.goods.id,
                body: {
                    accept: true,
                    chatId: this.$route.params.id,
                }
            }).catch(error => {
                this.showToast(error.response.data.message, 'error');
            });
        });

      eventBus.$on('onEndChat', () => {
            this.blockChat({
                id: this.$route.params.id
            })
                .then(() => {
                    modal.hideModal();
                    this.showToast(this.$t('toast.chat-closed'));
                    this.$router.push({
                        name: 'goods',
                        params: { id: this.goods.id }
                    });
                })
                .catch(error => this.showToast(error.response.data.message, 'error'));
        });
    },
    mounted() {
      chatInit.initMenuButton();
      chatInit.initModalButtons();


      this.timer = setInterval(this.checkNewMessages, 5000);
    },

    beforeDestroy() {
      clearInterval(this.timer);
      eventBus.$off('onEndChat');
      eventBus.$off('onBookingDeal');
      eventBus.$off('onApproveDeal');
      eventBus.$off('imageForMessageLoaded');
      eventBus.$off('applyMarker');
    },

    computed: {
      ...mapGetters(['user']),
    },
    methods: {
      ...mapMutations(['addMessages', 'initMessages', 'addNewMessage']),
      ...mapActions(['loadChatById', 'loadChatMessagesById', 'loadNewChatMessagesById', 'sendMessage', 'createChat', 'loadGoodsById', 'loadGoodsByChatId', 'confirmDeal', 'bookingDeal', 'blockChat', 'loadComplaintCategories']),
      goBack() {
        this.$router.back();
      },
      async loadMessages($state) {
        if (this.$route.name === 'newChat') {
          $state.complete();
          return;
        }
        let result = await this.loadChatMessagesById({
          id: this.chatId,
          query: { limit: 30 },
        });
        if (result.data.length) {
          this.addMessages({
            chatId: this.chatId,
            messages: result.data
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      },
      async checkNewMessages() {
        if (this.$route.name === 'newChat') return;
        let result = await this.loadNewChatMessagesById({
          id: this.chatId,
          query: { direction: "new" }
        });
        if(result.data.length) {
          for(let msg of result.data) {
            if(this.messages.find(el => el.id == msg.id)) continue;
            this.addNewMessage({
              chatId: this.chatId,
              message: msg,
            });
          }
        }
      },

      onCDEKClick() {
        this.$router.push({
          name: 'delivery',
          params: { id: this.$route.params.id }
        });
      },

      onSendMessageClick() {
        if (this.newMessage.text.length === 0) {
          return;
        }
        let message = { ...this.newMessage };
        this.newMessage.text = '';
        if (this.$route.name === 'newChat') {
          this.onCreateChat(message);
        } else {
          this.sendMessage({
            id: this.$route.params.id,
            body: message
          })
            .then((result) => {
              this.addNewMessage({
                chatId: this.chatId,
                message: result.data
              });
            })
            .catch(error => this.showToast(error.response.data.message, 'error'));
        }
      },
      sendGeo(geo) {
        this.sendMessage({
          id: this.$route.params.id,
          body: {
            type: 'geo',
            geo: geo
          }
        })
          .then((result) => {
            this.addNewMessage({
              chatId: this.chatId,
              message: result.data
            });
            this.newMessage.resourceId = null;
          })
          .catch(error => this.showToast(error.response.data.message, 'error'));
      },
      sendPhoto(resourceId) {
        // todo: по сути, условие не нужно, так как интерфейс для отправки фото есть только после создания чата, но мб надо будет вернуть, пусть будет.
        if (this.$route.name === 'newChat') {
          this.onCreateChat({
            type: 'image',
            resourceId: resourceId
          });
        } else {
          this.sendMessage({
            id: this.$route.params.id,
            body: {
              type: 'image',
              resourceId: resourceId
            }
          })
            .then((result) => {
              this.addNewMessage({
                chatId: this.chatId,
                message: result.data
              });
              this.newMessage.resourceId = null;
            })
            .catch(error => this.showToast(error.response.data.message, 'error'));
        }
      },
      goToChat(id) {
        this.$router.replace({
          name: 'chat',
          params: {
            id: id
          }
        });
      },
      createResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return null;
        }
      },
      onCreateChat(message) {
        this.createChat({
          body: {
            goodsId: this.$route.params.id,
            ...message
          }
        })
          .then((result) => {
            this.chatId = result.data.id;
            this.goToChat(result.data.id);
            this.initMessages(result.data.id);
            this.infinite++;
            this.newMessage.text = '';
          })
          .catch(error => this.showToast(error.response.data.message, 'error'));
      },
      onEnterPressed(event) {
        if (event.keyCode === 13) {
          this.onSendMessageClick();
        }
      },
      onChooseLocationClick() {
        eventBus.$emit('onChooseLocation', null)
        //modal.openModal('modal-choose-location');
      },
      onApproveClick() {
        modal.openModal('modal-approve');
      },
      onBookingClick() {
        modal.openModal('modal-booking');
      },
      onComplaintClicked() {
        eventBus.$emit('onOpenComplaint', {goodsId: this.goods.id, chatId: this.$route.params.id});
      },
      onGeoClicked(item) {
        let url = `https://google.com/maps/search/?api=1&query=${item.geo.latitude},${item.geo.longitude}&zoom=16`;
        window.open(url);
      },
      onEndChatClick() {
        modal.openModal('modal-end-chat');
      },
      onOpenPhotoModalClick(resourceId) {
          let that = this;
          let url = that.createResourceUrl(resourceId);
          eventBus.$emit('openPhotoModal', {url: url});
      },
    }
  };
</script>

<style scoped>
  .gmaps-icon {
    max-width: 128px;
  }
  .gmaps-icon:hover {
    cursor: pointer;
  }
</style>
